import React from "react";
import { CSSTransitionGroup } from "react-transition-group";
import download from "downloadjs";
import { createForm, formShape } from "rc-form";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-grid-system";
import Modal from "../Common/Modal";
import Button from "../Common/Buttons/ButtonPrimary";
import axios from "../../lib/axios-client";
import httpClient from "../../lib/HttpClient";
import history from "../../history";
import HubspotChat from "../../../src/components/Common/HubspotChat";

import security from "../../services/Security";

import styles from "./ReferenceViewer.module.scss";

/* UI Kit */
import {
  UikFormInputGroup,
  UikInput,
  UikButton,
  UikHeadline,
  UikDivider,
  UikDropdown,
  UikDropdownItem,
  Uikon
} from "@uik";
import "@uik/styles.css";
import "../../font.scss";

/* Papercurve Components */
import Header from "../shared/Header/Header";
import Reference from "./Reference";
import PDFTReference from "./PDFTReference";
import RefPanel from "./RefPanel";
import closeSVG from "../../images/icons/svg/close.svg";
import linkSVG from "../../images/icons/svg/link-grey.svg";
import defaultTooltipSVG from "../../images/icons/svg/info-tooltip-default.svg";
import activeTooltipSVG from "../../images/icons/svg/info-tooltip-active.svg";
import dimpleSVG from "../../images/icons/svg/toolbox-dimple.svg";

class ReferenceViewerBuild extends React.Component {
  constructor(props) {
    super();

    this.state = {
      document: {},
      renameModalOpen: false,
      documentLoaded: false,
      selectedBlockId: null,
      conversationBlockId: null,
      referenceBlockId: null,
      referenceTextCaption: null,
      existingReferenceBlockId: null,
      conversationPageNumber: null,
      currentUser: {
        id: security.getUserId(),
        role: security.getUserRole()
      },
      confirm_disabled: false,
      currentXfdfHighlightPageIndex: null,
      currentXfdfHighlightId: null,
      currentXfdfHighlightString: null,
      currentXfdfHighlightTextCaption: null,
      searchValue: "",
      references: null,
      showRenameModal: false,
      showDeleteModal: false,
      showDescription: false
    };
  }

  componentWillMount = () => {
    const docId = this.props.match.params.id;
    const readOnly =
      this.props.location.state !== undefined &&
        this.props.location.state.readOnly
        ? true
        : false;
    if (!this.state.documentLoaded) {
      httpClient.get(`/documents/${docId}.json`).then(response => {
        this.setState({
          document: response.data,
          readOnly: readOnly,
          documentTitleInputValue: response.data.title,
          documentDescriptionInputValue: response.data.description
        });
        httpClient.get(`/documents/${response.data.id}/document_references.json`).then(res => {
          console.log(res.data);
          this.setState({
            references: res.data,
            filteredReferences: res.data
          })
        })
      });
    }
  };

  handleDownload = () => {
    const token = security.getToken();

    const _that = this;
    axios({
      method: "get",
      url: `/documents/${this.state.document.id}/file`,
      responseType: "arraybuffer",
      headers: { Authorization: `Bearer ${token}` }
    }).then(function (response) {
      const content = response.headers["content-type"];
      download(
        response.data,
        `${_that.state.document.title}.${_that.state.document.file_extension}`,
        `${_that.state.document.mime_type}`
      );
    });
  };

  handleDelete = e => {
    e.preventDefault();
    const token = security.getToken();
    const docId = this.props.match.params.id;

    const confirmed = window.confirm(
      `Are you sure you want to delete ${this.state.document.title} ?`
    );

    if (confirmed) {
      httpClient.delete(`/documents/${docId}.json`).then(response => {
        console.log("deleted!");
        history.push("/drafts");
      });
    }
  };

  onHighlightedBlock = (blockId, caption) => {
    console.log(caption);
    this.setState({
      referenceBlockId: blockId,
      referencePageNumber: blockId.split("-")[2],
      referenceTextCaption: caption
    });
  };

  onClickedReferenceIndicator = e => {
    this.setState({
      referenceBlockId: e,
      referencePageNumber: e.split("-")[2]
    });
  };

  onReferenceSelection = () => {
    const blockId = this.state.selectedBlockId;
    const unhighlightElements = document.getElementsByClassName(
      "selectedDocumentText"
    );

    this.setState({
      selectedBlockId: null,
      referenceBlockId: blockId,
      referencePageNumber: blockId.split("-")[2]
    });

    while (unhighlightElements[0]) {
      unhighlightElements[0].classList.add("referencedText");
      unhighlightElements[0].classList.remove("selectedDocumentText");
    }
  };

  onCloseRefPanel = e => {
    const unhighlightElements = document.getElementsByClassName(
      "referencedText"
    );

    while (unhighlightElements[0]) {
      unhighlightElements[0].classList.remove("referencedText");
    }

    this.setState({
      referenceBlockId: null,
      referenceTextCaption: null
    });
  };

  addReference = () => {
    this.legacyAddReference();
  };

  legacyAddReference = () => {
    this.setState({ confirm_disabled: true });

    const documentBlockId = this.props.match.params.blockIdentifier;
    const referenceBlockId = this.state.referenceBlockId;
    const pageNumber = this.state.referencePageNumber;
    const docId = this.state.document.id;
    const parentDocId = this.state.document.reference_document_id;
    const referenceTextCaption = this.state.referenceTextCaption;

    const parentDocPageNumber = documentBlockId.split("-")[2];

    const referenceData = {
      reference: {
        document_id: parseInt(docId),
        page_number: parseInt(pageNumber),
        block_identifier: referenceBlockId,
        caption: referenceTextCaption
      },
      document_reference: {
        document_id: parseInt(parentDocId),
        page_number: parseInt(parentDocPageNumber),
        block_identifier: documentBlockId
      }
    };

    httpClient
      .post("/references.json", referenceData)
      .then(response => {
        history.push(`/drafts/${this.state.document.reference_document_id}`);
        this.setState({ confirm_disabled: false });
      })
      .catch(e => {
        this.setState({ confirm_disabled: false });
      });
  };

  onAddReference = (pageIndex, annotationId, annotationXfdfString, annotationText) => {
    if (this.props.match.params.library) {
      this.onAddSingleReference(pageIndex, annotationId, annotationXfdfString, annotationText);
    } else {
      // There can be a Reference object already.
      this.setState({ confirm_disabled: true });

      // These are retrieving the localstorage stored data for the parent document.
      const annotationHighlightXfdfId = localStorage.getItem("annotationHighlightXfdfId");
      const parentAnnotationHighlightXfdfPageNumber = localStorage.getItem("parentAnnotationHighlightXfdfPageNumber");
      const annotationHighlightXfdf = localStorage.getItem(annotationHighlightXfdfId);
      const parentDocId = localStorage.getItem("parentDocumentId");

      const referenceData = {
        reference: {
          document_id: parseInt(this.state.document.id),
          page_number: parseInt(pageIndex),
          xfdf_highlight_id: annotationId,
          xfdf_highlight_string: annotationXfdfString,
          caption: annotationText
        },
        document_reference: {
          document_id: parseInt(parentDocId),
          page_number: parseInt(parentAnnotationHighlightXfdfPageNumber),
          xfdf_highlight_id: annotationHighlightXfdfId,
          xfdf_highlight_string: annotationHighlightXfdf
        }
      };

      console.log('referenceData', referenceData);

      httpClient
        .post("/references.json", referenceData)
        .then(response => {
          history.push({
            pathname: `/drafts/${parentDocId}`,
            search: `?xfdfId=${annotationHighlightXfdfId}`
          })
          this.setState({ confirm_disabled: false });
        })
        .catch(e => {
          this.setState({ confirm_disabled: false });
        });
    }
  };

  onAddSingleReference = (pageIndex, annotationId, annotationXfdfString, annotationText) => {
    // There can be a Reference object already.
    this.setState({ confirm_disabled: true });

    const referenceData = {
      reference: {
        document_id: parseInt(this.state.document.id),
        page_number: parseInt(pageIndex),
        xfdf_highlight_id: annotationId,
        xfdf_highlight_string: annotationXfdfString,
        caption: annotationText
      },
      document_reference: { document_id: 0 }
    };

    console.log('referenceData', referenceData);

    let references = this.state.references;
    if (!references.find(ref => ref.xfdf_highlight_id == annotationId)) {
      httpClient
        .post("/references.json", referenceData)
        .then(response => {
          this.setState({ confirm_disabled: false });

          // Update the reference list

          references.push(response.data);
          this.setState({ references: references });
        })
        .catch(e => {
          this.setState({ confirm_disabled: false });
        });
    }
  };

  onAnnotationSelect = (pageIndex, xfdfHighlightId) => {
    this.setState({
      referenceBlockId: null,
      referenceTextCaption: null,
      currentXfdfHighlightPageIndex: pageIndex,
      commentXfdfHighlightId: xfdfHighlightId
    });
  };

  onAnnotationDeselect = () => {
    this.setState({
      referenceBlockId: null,
      referenceTextCaption: null,
      currentXfdfHighlightTextCaption: null,
      currentXfdfHighlightPageIndex: null,
      commentXfdfHighlightId: null
    });
  };

  onTextSelection = (pageIndex, currentXfdfHighlightId, currentXfdfHighlightString, currentXfdfHighlightText) => {
    // This should be passed to (PDFTron) <PDFTReference onTextSelection={this.onTextSelection} />
    // It gets trigger after text selection occurs in PDFTron's WebViewer.
    // We want to retrieve the `currentXfdf*` and utilize them to pin a reference highlight.

    if (currentXfdfHighlightId) {
      this.setState({
        currentXfdfHighlightPageIndex: pageIndex,
        currentXfdfHighlightId: currentXfdfHighlightId,
        currentXfdfHighlightString: currentXfdfHighlightString,
        currentXfdfHighlightTextCaption: currentXfdfHighlightText
      });
    }
  };

  onCancelReference = () => {
    if (this.props.match.params.library == 'library') {
      this.props.history.push({
        pathname: `/libraries/${this.state.document.library_id}/references`,
      });
    } else {
      let documentPath = "drafts";

      if (this.props.location.state && this.props.location.state.isApproved) {
        documentPath = "documents";
      }

      if (this.props.location.state) {
        this.props.history.push({
          pathname: `/${documentPath}/${this.props.location.state.originalDocumentId}`,
          state: {
            versionId: this.props.location.state.versionId,
            versionNumber: this.props.location.state.versionNumber
          }
        });
      }
      else {
        const parentDocId = localStorage.getItem("parentDocumentId");

        this.props.history.push({
          pathname: `/drafts/${parentDocId}`,
        });
      }
    }
  };

  onSearchChange = e => {
    const searchValue = e.target.value;
    const filteredReferences = this.state.references.filter(reference => reference.caption && reference.caption.toLowerCase().includes(searchValue.toLowerCase()));
    
    this.setState({
      searchValue,
      filteredReferences
    })
  }

  referenceClicked = ref => {

    const webViewerEl = document.getElementById("webViewerReference");
    const instance = window.WebViewer.getInstance(webViewerEl);
    const annotation = instance.annotManager.getAnnotationById(ref.xfdf_highlight_id);

    instance.annotManager.selectAnnotation(annotation);
    instance.docViewer.setCurrentPage(ref.page.page_number);
  }

  onRenameSave = () => {
    httpClient.put(`/documents/${this.state.document.id}.json`, {
      title: this.state.documentTitleInputValue,
      description: this.state.documentDescriptionInputValue
    })
      .then(res => {
        this.setState({
          document: {
            ...this.state.document,
            title: res.data.title,
            description: res.data.description
          },
          documentTitleInputValue: res.data.title,
          description: res.data.description,
          showRenameModal: false
        })
      })
  }

  deleteReference = () => {
    httpClient.delete(`/documents/${this.state.document.id}`).then(res => {
      this.onCancelReference();
    })
  }

  highlightSearchTerm = (text, highlight) => {
    // Split on highlight term and include term into parts, ignore case
    const parts = text ? text.split(new RegExp(`(${highlight})`, 'gi')) : [];
    return <span> { parts.map((part, i) =>
        <span key={i} className={part.toLowerCase() === highlight.toLowerCase() ? styles.highlight : {} }>
            { part }
        </span>)
    } </span>;
  }

  renderReferencesList = () => {
    let referencesHT = {};
    if (this.state.filteredReferences) {
      this.state.filteredReferences.forEach(ref => {
        if (!referencesHT[ref.page.page_number]) {
          referencesHT[ref.page.page_number] = [];
        }
        referencesHT[ref.page.page_number].push(ref);
      })
      return (
        <div>
          {Object.entries(referencesHT).map(([key, refArr], index) =>
            <div key={index}>
              <div className={styles.pageNumber}>
                Page {key}
              </div>
              <div className={styles.dividerContainer} />
              <div>
                {refArr.map(ref =>
                  <div onClick={() => this.referenceClicked(ref)} className={styles.documentReferenceContainer}>
                    <div className={styles.referenceCaption}>
                      {this.highlightSearchTerm(ref.caption, this.state.searchValue)}
                    </div>
                    <div className={styles.referenceLinks}>
                      <img src={linkSVG} />
                      <div>
                        {ref.document_references.length}
                      </div>
                    </div>
                    <div className={styles.dividerContainer} />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )
    }
  }

  render() {
    const { getFieldError, getFieldDecorator } = this.props.form;

    const actionsDropDown = ({ onClick }) => {
      return (
        <div
          className={styles.referenceActionsDropDownContainer}
          onClick={onClick}
        >
          &#9679;&#9679;&#9679;
        </div>
      );
    };
    return (
      <div className={styles.referenceViewer}>
        <div className={styles.referenceDocumentContainer}>
          <div className={styles.headerContainer}>
            <div className={styles.header}>
              {this.props.match.params.blockIdentifier ? "Reference" : "Select reference source"}
            </div>
            <div className={styles.titleDescriptionContainer}>
              <div className={styles.documentTitle}>
                {this.state.document && this.state.document.title}
              </div>
              <div className={styles.documentDescription}>
                <img
                  onClick={() => this.setState({ showDescription: !this.state.showDescription })}
                  src={this.state.showDescription ? activeTooltipSVG : defaultTooltipSVG}
                />
                {this.state.showDescription &&
                  <div className={styles.descriptionBox}>
                    <img src={dimpleSVG} />
                    <div className={styles.descriptionText}>
                      {this.state.document.description ? this.state.document.description : "No description exists for this reference."}
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
          {this.state.document.id && !this.state.document.not_pdftron && (
            <div className={styles.referenceContainer}>
              <PDFTReference
                doc={this.state.document}
                docId={this.state.document.id}
                docExtension={this.state.document.file_extension}
                onTextSelection={this.onTextSelection}
                onAnnotationSelect={this.onAnnotationSelect}
                onAnnotationDeselect={this.onAnnotationDeselect}
                onAddReference={this.onAddReference}
                showRenameModal={() => this.setState({ showRenameModal: true })}
                showDeleteModal={() => this.setState({ showDeleteModal: true })}
                downloadDocument={this.handleDownload}
                xfdfHightlightId={this.props.match.params.blockIdentifier}
                references={this.state.references}
              />
            </div>
          )}
        </div>
        <div className={styles.sideBarContainer}>
          <div className={styles.sidebarHeader}>
            <div className={styles.container}>
              <HubspotChat darkMode={true} />
              <div className={styles.closeButton}>
                <img src={closeSVG} onClick={this.onCancelReference} />
              </div>
            </div>
            <div className={styles.searchInput}>
              <UikInput
                placeholder="Search Annotations"
                className={styles.searchInputField}
                onChange={this.onSearchChange}
                value={this.state.searchValue}
              >
              </UikInput>
            </div>
          </div>
          <div className={styles.referencesContainer}>
            {this.renderReferencesList()}
          </div>
          <div className= {styles.doneReferenceButton}>
            <Button
              original
              text="Done"
              onClick={this.onCancelReference}
            />
          </div>
        </div>
        <Modal
          type="regular"
          hideModal={() => this.setState({ showRenameModal: false })}
          visible={this.state.showRenameModal}
        >
          <div className="heading">
            Rename reference
          </div>
          <UikDivider />
          <div className="body">
            <div className={styles.modalTitleLabel}>
              Title
            </div>
            <input
              value={this.state.documentTitleInputValue}
              onChange={e => this.setState({ documentTitleInputValue: e.target.value })}
              className={styles.titleModalInput}
            />
            <div className={styles.modalDescriptionLabel}>
              Description
            </div>
            <textarea
              value={this.state.documentDescriptionInputValue}
              onChange={e => this.setState({ documentDescriptionInputValue: e.target.value })}
              className={styles.descriptionModalInput}
            />
          </div>
          <UikDivider />
          <div className="buttons">
            <Button
              onClick={this.onRenameSave}
              text="Save"
              original
            />
            <Button
              onClick={() => this.setState({ showRenameModal: false })}
              text="Cancel"
              transparent
            />
          </div>
        </Modal>
        <Modal
          type="alert"
          hideModal={() => this.setState({ showDeleteModal: false })}
          visible={this.state.showDeleteModal}
        >
          <div className="heading">
            {(this.state.references && this.state.references.length) ? "Reference document can't be deleted" : "Confirm delete"}
          </div>
          <UikDivider />
          <div className="body">
            <div className={styles.deleteReferenceModalBody}>
              {(this.state.references && this.state.references.length) ?
                "This reference document cannot be deleted because it is being used by other documents."
                : "Are you sure you want to delete this annotation?"
              }
            </div>
          </div>
          <UikDivider />
          {(this.state.references && this.state.references.length) ?
            <div className="buttons">
              <Button
                onClick={() => this.setState({ showDeleteModal: false })}
                text="OK"
                original
              />
            </div>
            :
            <div className="buttons">
              <Button
                onClick={this.deleteReference}
                text="Delete"
                original
              />
              <Button
                onClick={() => this.setState({ showDeleteModal: false })}
                text="Cancel"
                transparent
              />
            </div>
          }
        </Modal>
      </div >
    );
  }
}

const ReferenceViewer = createForm()(ReferenceViewerBuild);
export default ReferenceViewer;
