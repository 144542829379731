import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { createForm, formShape } from "rc-form";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import { Line } from "rc-progress";

import styles from "./ReferencePanel.module.scss";
import axios from "../../lib/axios-client";
import httpClient from "../../lib/HttpClient";
import notificationService from "../../services/Notifications";
import referencesService from "../../services/References";

import referenceIcon from "../../images/icons/16px/link.svg";
import Warning from "../../images/icons/png/warning.svg";
import moreIcon from "../../images/icons/more.svg";
import Close from "../../images/icons/svg/close.svg";
import Button from "../Common/Buttons/ButtonPrimary";
import noUploadIcon from "../../images/icons/png/noUpload.png";


import security from "../../services/Security";

/* UI Kit */
import {
  Uikon,
  UikButton,
  UikFormInputGroup,
  UikInput,
  UikDivider,
  UikDropdown,
  UikDropdownItem
} from "@uik";
import "@uik/styles.css";
import "../../font.scss";

/* Variables */

class ReferencePanelBuild extends React.Component {
  constructor() {
    super();

    this.state = {
      references: [],
      referenceFiles: [],
      documentReferences: null,
      searchValue: null,
      filteredReferences: []
    };
  }

  legacyRetrieveReference = () => {
    httpClient
      .get(`/document/references/${this.props.referenceBlockId}.json`)
      .then(response => {
        this.setState({ documentReferences: response.data });
      });
  };

  currentRetrieveReference = () => {
    httpClient
      .get(`/document/references/${this.props.referenceXfdfHighlightId}.json`)
      .then(response => {
        this.setState({ documentReferences: response.data });
        if (response.data.length > 0) {
          this.props.setDocumentReferenceId(response.data[0].id);
        }
      });
  };

  componentWillMount = () => {
    if (this.props.legacyDocument) {
      this.legacyRetrieveReference();
    } else {
      this.currentRetrieveReference();
    }
    document.addEventListener("reload_references", e => {
      httpClient
        .get(`/documents/${this.props.docId}/references.json`)
        .then(response => {
          this.setState({ references: response.data });
        });
    });
    httpClient
      .get(`/documents/${this.props.docId}/references.json`)
      .then(response => {
        console.log("response", response);
        this.setState({
          references: response.data,
          filteredReferences: response.data
        });
      });
  };

  componentDidUpdate(prevProps) {
    if (this.props.referenceXfdfHighlightId === null && prevProps.referenceXfdfHighlightId !== null) {
      this.setState({ documentReferences: null });
    }
    if (this.props.referenceXfdfHighlightId !== prevProps.referenceXfdfHighlightId && this.props.referenceXfdfHighlightId !== null) {
      httpClient
        .get(`/document/references/${this.props.referenceXfdfHighlightId}.json`)
        .then(response => {
          this.setState({ documentReferences: response.data });
          if (response.data.length > 0) {
            this.props.setDocumentReferenceId(response.data[0].id);
          }
        });
    }
  }

  onReferenceDocumentsDrop = (acceptedFiles, rejectedFiles) => {
    const token = security.getToken();

    this.setState({
      referenceFiles: this.state.referenceFiles.concat(acceptedFiles)
    });

    acceptedFiles.map((referenceFile, index) => {
      const pctIndex = this.state.referenceFiles.length + index;

      const postConfig = {
        headers: {
          Authorization: "Bearer " + token
        },
        onUploadProgress: progressEvent => {
          const referenceFilePercent = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          if (referenceFilePercent >= 100) {
            this.setState({ ["referenceFilePercentages" + pctIndex]: 100 });
          } else {
            this.setState({
              ["referenceFilePercentages" + pctIndex]: referenceFilePercent
            });
          }
        }
      };

      this.setState({ ["referenceFilePercentages" + pctIndex]: 0 });

      const referenceFormData = new FormData();
      referenceFormData.append("document[title]", referenceFile.name);
      referenceFormData.append("document[document_file]", referenceFile);
      referenceFormData.append(
        "document[reference_document_id]",
        this.props.docId
      );
      referenceFormData.append("document[reference_document]", true);

      axios
        .post("/documents.json", referenceFormData, postConfig)
        .then(response => {
          let docRefs = this.state.references;
          docRefs.push(response.data);
          //let referenceDocuments = this.state.referenceDocuments;
          //referenceDocuments.push(response.data);
          this.setState({
            referenceDocuments: docRefs,
            filteredReferences: docRefs,
            referenceFiles: []
          });
          console.log("saved!");
        })
        .catch(error => {
          security.checkAuth(error);
          console.log("error", error);
          this.setState({
            ["referenceFileError" + pctIndex]: "error",
            ["referenceFilePercentages" + pctIndex]: 100
          });
        });
    });
  };

  renderReferenceDocumentsUploader = () => {
    return (
      <div className={styles.referenceContentUploaderBox}>
        <Dropzone onDrop={this.onReferenceDocumentsDrop} multiple={true}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div
                {...getRootProps()}
                className={classNames("dropzone", {
                  "dropzone--isActive": isDragActive
                })}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <div>
                    <span className={styles.uploaderIcon}>
                      <Uikon>cloud_up</Uikon>
                    </span>
                    <span className={styles.uploaderPlaceholder}>
                      Drop reference documents here
                    </span>
                  </div>
                ) : (
                    <div>
                      <span className={styles.uploaderIcon}>
                        <Uikon>cloud_up</Uikon>
                      </span>
                      <span className={styles.uploaderPlaceholder}>
                        Drop files here to upload
                    </span>
                    </div>
                  )}
              </div>
            );
          }}
        </Dropzone>
      </div>
    );
  };

  renderReferenceDocument = (refDoc, idx) => {
    let refId;

    if (this.props.legacyDocument) {
      refId = this.props.referenceBlockId;
    } else {
      refId = localStorage.getItem("annotationHighlightXfdfId");
    }

    return (
      <div className={styles.newReferenceLinkContainer}>
        <Link
          to={{
            pathname: `/references/${refDoc.id}`
          }}
          className={styles.referenceLink}
        >
          {refDoc.title}
        </Link>
      </div>
    );
  };

  renderDocumentReference = (ref, idx) => {
    return (
      <div
        key={ref.reference.document_id}
        className={styles.referenceContentUploading}
      >
        <Link
          to={{
            pathname: `/references/${ref.reference.document_id}/pid/${this.props.referenceXfdfHighlightId}`,
            state: {
              docId: ref.document_id,
              readOnly: this.props.readOnly,
              versionId: this.props.versionId,
              versionNumber: this.props.versionNumber,
              originalDocumentId: this.props.originalDocumentId,
              isApproved: this.props.isApproved
            }
          }}
          className={styles.referenceLink}
        >
          <div className={styles.referenceCaption}>{ref.reference.caption}</div>
          <div className={styles.referenceDocumentTitle}>
            {ref.reference.document && (<span>{ref.reference.document.title}</span>)}
          </div>
          <div className="clear"></div>
        </Link>
      </div>
    );
  };

  onDeleteReference = () => {
    const { referenceXfdfHighlightId } = this.props;

    if (window.confirm("Are you sure you want to delete this Reference?")) {
      httpClient
        .delete(`/document_references/${this.state.documentReferences[0].id}.json`)
        .then(response => {
          if (referenceXfdfHighlightId) {
            const webViewerEl = document.getElementById('webViewer');
            const annotManager = window.WebViewer.getInstance(webViewerEl).docViewer.getAnnotationManager();
            const annot = annotManager.getAnnotationById(referenceXfdfHighlightId);

            annotManager.deleteAnnotation(annot);
          }

          this.props.close();
          notificationService.addNotification(
            "Reference removed.",
            "Reference has been deleted.",
            "danger"
          );
          referencesService.reloadReferences();
          referencesService.decrementReferences();
        }).catch(error => {
          security.checkAuth(error);
          this.props.close();
          notificationService.addNotification(
            "Reference deletion not allowed.",
            "You do not have permission to delete this reference.",
            "danger"
          );
        });
    }
  };

  renderReferenceDocumentsUploaderProgress = (rf, idx) => {
    const rfProgressPercentage = this.state["referenceFilePercentages" + idx];
    const rfError = this.state["referenceFileError" + idx];
    return (
      <div className={styles.referenceContentUploading}>
        <div>
          {!rfError && (
            <span className={styles.uploaderIcon}>
              <img src={referenceIcon} />
            </span>
          )}
          <div className={styles.uploadingFilename}>{rf.name}</div>
          {rfError && (
            <div className={styles.errorBox}>
              <img src={Warning} className={styles.warning} />
              <span className={styles.errorMessage}>
                This type of file is not supported
              </span>
            </div>
          )}
          {!rfError && rfProgressPercentage == 100 && (
            <span className={styles.uploaderIconCheck}>
              <Uikon>check</Uikon>
            </span>
          )}
          {rfProgressPercentage < 100 && (
            <Line
              className={styles.uploadProgress}
              percent={rfProgressPercentage}
              strokeWidth="1"
              strokeColor="#1b8c96"
              strokeLinecap="square"
            />
          )}
        </div>
      </div>
    );
  };

  onSearchChange = e => {
    const searchValue = e.target.value;
    const filteredReferences = this.state.references.filter(reference => reference.title.toLowerCase().includes(searchValue.toLowerCase()));
    this.setState({
      searchValue,
      filteredReferences
    })
  }

  render() {

    const actionsDropDown = ({ onClick }) => {
      return (
        <span onClick={onClick}>
          <img className={styles.moreIcon} src={moreIcon} />
        </span>
      );
    };

    return (
      <div id="referencePanel" className={styles.referencePanel}>
        {this.state.documentReferences !== null && this.state.documentReferences.length > 0 && !this.state.addToExistingReferencePanel &&
          <div className={styles.sidePanelHeader}>
            <span>Select Reference</span>
            <span
              className={styles.closeComment}
              onClick={() => this.props.close()}
            >
              &times;
            </span>
            {(security.getUserRole() !== "viewer" && (this.state.documentReferences !== null && this.state.documentReferences.length > 0)) &&
              (
                <div className={styles.referenceActionsDropDownContainer}>
                  <UikDropdown
                    DisplayComponent={actionsDropDown}
                    position="bottomRight"
                  >
                    <UikDropdownItem onClick={e => this.onDeleteReference(e)}>
                      Delete
                </UikDropdownItem>
                  </UikDropdown>
                </div>
              )}
          </div>
        }
        {(this.state.documentReferences !== null && this.state.documentReferences.length > 0 && !this.state.addToExistingReferencePanel) &&
          <div className={styles.sidePanelOptions}>
            {this.state.documentReferences.map((ref, idx) => {
              return this.renderDocumentReference(ref, idx);
            })}
            {!this.props.readOnly && (
            <div className={styles.addReferenceButton}>
              <Button
                original
                text="Add to Reference"
                onClick={() => this.setState({addToExistingReferencePanel: true})}
              />
            </div>
            )}
          </div>
        }
        {
          this.state.addToExistingReferencePanel &&
          <div>
            <div className={styles.newReferenceSidePanelHeader}>
              <span
                className={styles.closeComment}
                onClick={() => this.props.close()}
              >
                <img src={Close} />
              </span>
              <div className={styles.chooseDoucmentTextNew}>
                Choose Document
              </div>
            </div>
            <div className={styles.referencesContainer}>
              {this.state.filteredReferences.map((ref, idx) => {
                return this.renderReferenceDocument(ref, idx);
              })}
              {this.state.referenceFiles &&
                this.state.referenceFiles.map((rf, index) => {
                  return this.renderReferenceDocumentsUploaderProgress(rf, index);
                })}
            </div>
            <div className={styles.addReferenceButtonNew}>
              <Button
                original
                text="Add reference document"
                onClick={this.props.showReferencesLibrary}
              />
            </div>
          </div>
        }
        {(this.state.documentReferences !== null && this.state.documentReferences.length === 0) &&
          <div className={styles.newReferenceSidePanelHeader}>

            <span className={styles.newReferenceHeader}>New Reference</span>
            <span
              className={styles.closeComment}
              onClick={() => this.props.close()}
            >
              <img src={Close} />
            </span>
            <div className={styles.chooseDoucmentText}>
              Choose document
            </div>
            {(this.state.references && this.state.references.length) > 0 &&
              <UikInput
                placeholder="Search Documents"
                className={styles.searchInputField}
                onChange={this.onSearchChange}
                value={this.state.searchValue}
              >
              </UikInput>
            }
          </div>
        }
        {(this.state.documentReferences !== null && this.state.documentReferences.length === 0) &&
          <div className={this.state.filteredReferences.length > 0 ? styles.sidePanelOptions : styles.emptySidePanelOptions}>
            {this.state.filteredReferences.map((ref, idx) => {
              return this.renderReferenceDocument(ref, idx);
            })}
            {this.state.referenceFiles &&
              this.state.referenceFiles.map((rf, index) => {
                return this.renderReferenceDocumentsUploaderProgress(rf, index);
              })}
              {this.state.filteredReferences.length > 0 
                ?
                <div className= {styles.addReferenceButton}>
                  <Button
                    original
                    text="Add reference"
                    onClick={this.props.showReferencesLibrary}
                  />
                </div>
                :
                <div className= {styles.emptyStateContainer}>
                  <img className={styles.emptyStateUploaderIcon} src={noUploadIcon}></img>
                  <span className={styles.emptyStateMessage}>There are no reference documents.</span>
                  <Button
                    original
                    text="Add reference"
                    onClick={this.props.showReferencesLibrary}
                  />
                </div>
              }
          </div>
        }
      </div>
    );
  }
}

ReferencePanelBuild.propTypes = {
  referenceBlockId: PropTypes.string,
  close: PropTypes.func,
  docId: PropTypes.number,
  pageNumber: PropTypes.string,
  legacyDocument: PropTypes.bool,
  isApproved: PropTypes.bool,
  currentXfdfHighlightId: PropTypes.string,
  currentXfdfHighlightString: PropTypes.string
};

const ReferencePanel = createForm()(ReferencePanelBuild);
export default ReferencePanel;
