import React, { useState, useEffect } from "react";

import Button from "../Common/Buttons/ButtonPrimary";
import CloseSVG from "../../images/icons/svg/icon_close_white.svg";
import SearchSVG from "../../images/icons/svg/search-icon.svg";
import SortSVG from "../../images/icons/svg/sort-icon-white.svg";
import UploadSVG from "../../images/icons/svg/upload.svg";
import UploadSuccessSVG from "../../images/icons/png/checkmark.png";
import HubspotChat from "../../../src/components/Common/HubspotChat";

import Modal from "../Common/Modal";
import { apiUrl } from "../../lib/axios-client";
import { UikDivider, UikToggle, Uikon } from "@uik";
import Dropzone from "react-dropzone";
import httpClient from "../../lib/HttpClient";
import axios from "../../lib/axios-client";
import security from "../../services/Security";
import referencesService from "../../services/References";
import notificationService from "../../services/Notifications";
import history from "../../history";

import "./ReferencesLibrary.scss";
import DocumentThumbnail from "../Documents/DocumentThumbnail";

export const ReferencesLibrary = props => {

    const [showUploadModal, setShowUploadModal] = useState(false);
    const [libraryName, setLibraryName] = useState(null);
    const [sharedReferences, setSharedReferences] = useState(null);
    const [referenceDocumentFilename, setReferenceDocumentFilename] = useState("");
    const [referenceDocumentTitle, setReferenceDocumentTitle] = useState("");
    const [referenceDocumentDescription, setReferenceDocumentDescription] = useState("");
    const [referenceDocumentUploadedPercentage, setReferenceDocumentUploadedPercentage] = useState(null);
    const [shareReference, setShareReference] = useState(true);
    const [uploadedReferenceDocument, setUploadedReferenceDocument] = useState(null);
    const [libraryEmpty, SetLibraryEmpty] = useState(true);

    useEffect(() => {
        const libraryId = props.libraryId || props.match.params.id;
        httpClient.get(`/references_library/${libraryId}.json`).then(res => {
            setLibraryName(res.data.library_name);
            setSharedReferences(res.data.shared_references);
            if(res.data.shared_references.length !== 0) {
                SetLibraryEmpty(false)
            }
        })
    }, [])

    const renderReferenceDocuments = () => {
        if (libraryEmpty) {
            return <div className="emptyLibraryMessageContainer">
            <div className="messageComponentsContainer">
                <div className="messageText">
                There are no reference documents in this library. Upload a document now.
                </div>
                <div className="uploadButton">
                <Button
                    onClick={() => setShowUploadModal(true)}
                    text="Upload"
                    original />
                </div>
            </div>
            </div>
        } else {
            return sharedReferences.map((sharedReference, index) => (
                <div key={index}>
                    <DocumentThumbnail key={index} doc={sharedReference} fromDraft={true} />
                </div>
            ))
        }
    }

    const onUploadReferenceDocument = (acceptedFiles, rejectedFiles) => {
        const token = security.getToken();

        const postConfig = {
            headers: {
                Authorization: "Bearer " + token
            },
            onUploadProgress: progressEvent => {
                const documentPercent = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                if (documentPercent >= 100) {
                    setReferenceDocumentUploadedPercentage(100);
                } else {
                    setReferenceDocumentUploadedPercentage(documentPercent);
                }
            }
        };

        const documentFormData = new FormData();

        documentFormData.append("document[title]", acceptedFiles[0].name.replace(/\..*$/, ""));
        documentFormData.append("document[document_file]", acceptedFiles[0]);
        documentFormData.append("document[reference_document]", true);

        setReferenceDocumentUploadedPercentage(0);
        setReferenceDocumentFilename(acceptedFiles[0].name);

        axios
            .post("/documents.json", documentFormData, postConfig)
            .then(response => {
                if (referenceDocumentTitle === "") {
                    setReferenceDocumentTitle(response.data.title);
                }
                setUploadedReferenceDocument(response.data);
            })
            .catch(error => {
                setReferenceDocumentUploadedPercentage(null);
                notificationService.addNotification(
                    "Not supported",
                    "This kind of file is not supported.",
                    "warning"
                );
            });
    }

    const saveReferenceDocument = () => {

        const documentData = {
            document: {
                title: referenceDocumentTitle,
                description: referenceDocumentDescription,
                shared_reference: shareReference
            }
        }

        if (!shareReference) {
            documentData.document.reference_document_id = props.docId;
        } else {
            documentData.document.library_id = props.libraryId;
        }

        httpClient.put(`/documents/${uploadedReferenceDocument.id}.json`, documentData)
            .then(res => {
                history.push(`/references/${uploadedReferenceDocument.id}`)
            })
    }

    const cancelUpload = () => {
        setUploadedReferenceDocument(null);
        setShowUploadModal(false);
        setReferenceDocumentTitle("");
        setReferenceDocumentDescription("");
        setReferenceDocumentUploadedPercentage(null);
        setShareReference(true);
    }

    return (
        <div className="references-library">
            <div className="container">
                <div className="header">
                    <div className="left-content">
                        <div className="title">
                            Choose a reference document
                        </div>
                        <div className="sub-title">
                            Library: {libraryName}
                        </div>
                    </div>
                    <div className="right-content">
                        <div className="first-row">
                            <div className={`${libraryEmpty ? "upload-button-hide" : "upload-button"}`}>
                                <Button
                                    onClick={() => setShowUploadModal(true)}
                                    text="Upload"
                                    original
                                />
                                <HubspotChat darkMode={true} />
                            </div>
                            <div className="close-button" onClick={props.onClose}>
                                <img src={CloseSVG} />
                            </div>
                        </div>
                        <div className="second-row">
                            <div className="search-button">
                                <img src={SearchSVG} />
                            </div>
                            <div className="sort-button">
                                <img src={SortSVG} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="body">
                    {sharedReferences && renderReferenceDocuments()}
                </div>
            </div>
            <Modal
                hideModal={() => setShowUploadModal(false)}
                visible={showUploadModal}
                type="regular"
            >
                <div className="heading">
                    Upload a new reference document
                </div>
                <UikDivider />
                <div className="body">
                    {referenceDocumentUploadedPercentage === null &&
                        <div className="reference-file-uploader">
                            <Dropzone onDrop={onUploadReferenceDocument} multiple={false}>
                                {({ getRootProps, getInputProps, isDragActive }) => {
                                    return (
                                        <div
                                            {...getRootProps()}
                                        >
                                            <input {...getInputProps()} />
                                            {(
                                                <div className="uploader-container">
                                                    <div className="uploader-container-left">
                                                        <img className="icon" src={UploadSVG} />
                                                        <span className="text">
                                                            Drop files here to upload
                                                    </span>
                                                    </div>
                                                    <div className="uploader-container-right">
                                                        <Button
                                                            transparent
                                                            text="Browse"
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    );
                                }}
                            </Dropzone>
                        </div>
                    }
                    {uploadedReferenceDocument &&
                        <div className="reference-upload-success">
                            <div className="uploader-container">
                                <div className="uploader-container-left">
                                    <div className="icon">
                                        <Uikon>attachment</Uikon>
                                    </div>
                                    <span className="text">
                                        {referenceDocumentFilename}
                                    </span>
                                </div>
                                <div className="uploader-container-right">
                                    <img src={UploadSuccessSVG} />
                                </div>
                            </div>
                        </div>
                    }
                    <div className="reference-document-uploaded-details">
                        <div className="label">
                            title
                        </div>
                        <div className="title-input">
                            <input
                                type="text"
                                onChange={e => setReferenceDocumentTitle(e.target.value)}
                                value={referenceDocumentTitle}
                                placeholder="Type the name of your reference document"
                            />
                        </div>
                        <div className="label">
                            description
                        </div>
                        <div className="description-input">
                            <textarea
                                placeholder="Type a description of your reference document"
                                onChange={e => setReferenceDocumentDescription(e.target.value)}
                                value={referenceDocumentDescription}
                            />
                        </div>
                        <div className="share-reference-container">
                            <UikToggle
                                label="Share with other documents in this library"
                                defaultChecked={shareReference}
                                onChange={e => setShareReference(e.target.checked)}
                            />
                            <div className="subtitle">
                                Control whether you want to make this reference document available to others in this library, or keep it private.
                            </div>
                        </div>
                    </div>
                </div>
                <UikDivider />
                <div className="buttons">
                    <Button
                        onClick={() => saveReferenceDocument()}
                        text="Upload"
                        original
                    />
                    <Button
                        onClick={cancelUpload}
                        text="Cancel"
                        transparent
                    />
                </div>
            </Modal>
        </div >
    )
}
