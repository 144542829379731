import React, { useState } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import PDFViewer from '../PDFViewer/PDFViewer';
import WebViewerBackend from '../../backends/WebViewer';
import { apiUrl } from "../../lib/axios-client";
import security from "../../services/Security";
import httpClient from "../../lib/HttpClient";

import styles from "./PDFTReference.module.scss";

class Menu extends React.Component {
  state = {
    showDropdown: false
  }

  onClick = () => {
    this.setState({
      showDropdown: !this.state.showDropdown,
      userRole: security.getUserRole()
    })
  }

  render() {
    let color = "#ADB5BD";
    if (this.state.showDropdown) {
      color = "#1A7285";
    }
    return (
      <div>
        <div
          style={{
            color,
            cursor: "pointer",
            fontSize: "10px",
            marginRight: "4px",
            marginLeft: "4px"
          }}
          onClick={this.onClick}
        >
          &#9679;&#9679;&#9679;
        </div>
        {this.state.showDropdown && <div
          style={{
            position: "absolute",
            top: "37px",
            right: 0,
            backgroundColor: "white",
            width: "192px",
            borderRadius: "4px",
            zIndex: "99"
          }}
        >
          <div
            style={{
              color: "#3e3f42",
              padding: "8px 16px",
              cursor: "pointer"
            }}
            onClick={() => { this.props.downloadDocument(); this.setState({ showDropdown: false }) }}
          >
            Download
          </div>
          {this.state.userRole !== "viewer" &&
          <div>
            <div
              style={{
                height: "1px",
                background: "#EAEDF3"
              }}
            >
            </div>
              <div
              style={{
                color: "#3e3f42",
                padding: "8px 16px",
                cursor: "pointer"
              }}
              onClick={() => { this.props.showDeleteModal(); this.setState({ showDropdown: false }) }}
            >
              Delete
            </div>
            <div
              style={{
                height: "1px",
                background: "#EAEDF3"
              }}
            >
            </div>
            <div
              style={{
                color: "#3e3f42",
                padding: "8px 16px",
                cursor: "pointer"
              }}
              onClick={() => { this.props.showRenameModal(); this.setState({ showDropdown: false }) }}
            >
              Rename
            </div>
          </div>
          }
        </div>
        }
      </div>
    );
  }
}

class PDFTReference extends React.Component {
  constructor(props) {
    super();

    this.viewer = React.createRef();
    this.docViewer = null;
    this.annotManager = null;
    this.instance = null;
    this.annotations = null;

    this.state = {
      webViewerLoaded: false,
      selectedQuads: null,
      currentPageIndex: null,
      currentAnnotation: null,
      currentAnnotationXfdf: null,
      currentAnnotationText: null,
    };
  }

  shouldComponentUpdate = () => {
    return false;
  };

  componentDidMount = () => {
    const token = security.getToken();

    window.addEventListener("resize", this.onResize);

    // this.onResize();

    this.viewer = window.WebViewer({
      ui: 'legacy',
      path: '/vendor/pdf/lib',
      css: '/vendor/pdf/lib/ui/ReferencePDFtronStyle.css',
      disabledElements: [
        'outlinesPanelButton', 'contextMenuPopup',
        'outlinesPanel', 'notePopup',
        'thumbnailControl', 'documentControl',
        'annotationContentOverlay', 'layersPanelButton',
        'toolStylePopup', 'highlightToolButton',
        'highlightToolButton2', 'highlightToolButton3',
        'highlightToolButton4', 'underlineToolButton',
        'squigglyToolButton', 'strikeoutToolButton',
        'ellipseToolButton', 'lineToolButton',
        'arrowToolButton', 'polylineToolButton',
        'polygonToolButton', 'cloudToolButton',
        'calloutToolButton', 'stampToolButton',
        'fileAttachmentToolButton', 'cropToolButton',
        'freeHandToolButton', 'freeHandToolButton2',
        'freeHandToolButton3', 'freeHandToolButton4',
        'rectangleToolButton'
      ],
      licenseKey: 'Paper Curve Inc(papercurve.com):OEM:Papercurve::B+:AMS(20220311):A2A5766204D7A60A7360B13AC982737860613FEB89162A339DAD05330A2289E6549231F5C7',
    }, document.getElementById('webViewerReference')).then(instance => {
      const { docViewer, annotManager, Annotations } = instance;

      this.annotations = Annotations;
      this.instance = instance;
      this.docViewer = docViewer;
      this.annotManager = annotManager;

      let _that = this;

      // Set leftPanel
      instance.setActiveLeftPanel('thumbnailsPanel');

      // Remove headeer toolbar tools
      instance.setHeaderItems(function (header) {
        let items = header.getItems();
        let panSelect = items.splice(3, 3);
        items.splice(7, 13);
        items.splice(8, 1);
        items.splice.apply(items, [6, 0].concat(panSelect));
      });

      // Remove download option in more drop down menu
      instance.disableElement('downloadButton');
      instance.disableTools(['TextSelectTool', 'AnnotationComment']);

      // Load the document
      if (this.props.docId > 0) {
        instance.loadDocument(`${apiUrl}/documents/${this.props.docId}/file${this.props.docExtension}`, {
          customHeaders: {
            Authorization: `Bearer ${token}`
          }
        });
      }

      // Grab the text from the highlight
      instance.docViewer.on('textSelected', (quads, text, pageIndex) => {
        _that.setState({
          currentPageIndex: pageIndex,
          currentAnnotationText: text
        });
      });

      // Annotation Popup / Text Popup / Popup ?
      // console.log('getItems()', instance.textPopup.getItems());
      // instance.textPopup.update([]);
      instance.textPopup.update([
        {
          type: 'actionButton',
          img: '/assets/svg/toolbar-close.svg',
          onClick: this.deselectReference
        },
        {
          type: 'actionButton',
          img: '/assets/svg/toolbar-checkmark.svg',
          onClick: this.confirmReference
        }
      ]);

      if (this.props.xfdfHightlightId) {
        instance.disableElements(['textPopup', 'annotationPopup']);
      }

      instance.annotationPopup.update([
        {
          type: 'actionButton',
          img: '/assets/svg/toolbar-close.svg',
          onClick: this.deselectReference
        },
        {
          type: 'actionButton',
          img: '/assets/svg/toolbar-checkmark.svg',
          onClick: this.confirmExistingReference
        }
      ]);


      const newCustomElement = {
        type: 'customElement',
        render: () => <Menu showRenameModal={this.props.showRenameModal} showDeleteModal={this.props.showDeleteModal} downloadDocument={this.props.downloadDocument} />
      };
      instance.setHeaderItems(function (header) {
        header.get('searchButton').insertAfter(newCustomElement);
      });

      instance.docViewer.on('documentLoaded', () => {
        docViewer.getDocument().documentCompletePromise().then(async () => {
          let _that = this;
          let getDocId = this.props.docId;

          this.props.references.forEach((reference) => {
            if (reference.xfdf_highlight_id) {
              instance.annotManager.importAnnotCommand(reference.xfdf_highlight_string).then(annotationList => {
                annotationList.forEach((annot) => {
                  if (this.props.xfdfHightlightId) {
                    const hightlight = reference.document_references.find(documentReference => documentReference.xfdf_highlight_id === this.props.xfdfHightlightId)
                    if (hightlight) {
                      annotManager.selectAnnotation(annot);
                      docViewer.setCurrentPage(reference.page.page_number);
                    }
                  }
                })
              });
            }
          });

          instance.annotManager.drawAnnotationsFromList(instance.annotManager.getAnnotationsList())
        });
      });

      // This gets triggered when we pick comment and/or reference from the highlight TextPopup
      instance.annotManager.on('annotationChanged', (annotations, type, info) => {
        // info.imported is true by default for annotations from pdf and annotations added by importAnnotCommand
        if (info.imported) {
          return;
        }

        instance.annotManager.exportAnnotCommand().then((xfdf) => {
          annotations.forEach((annotation) => {
            if (type === 'add') {
              _that.setState({
                currentAnnotation: annotation,
                currentAnnotationXfdf: xfdf
              });

              _that.props.onAddReference(
                _that.state.currentPageIndex,
                annotation.Id,
                xfdf,
                _that.state.currentAnnotationText
              );
            }
          });
        });
      });

      instance.annotManager.on('annotationSelected', (annotationList, action) => {
        if (action === 'selected' && annotationList[0]) {
          const pageIndex = annotationList[0].PageNumber - 1;
          const annotationId = annotationList[0].Id;

          _that.setState({ selectedReferenceAnnotationId: annotationId });

          // Trigger comment panel in parent DocumentViewer.
          _that.props.onAnnotationSelect(
            pageIndex,
            annotationId
          );
        }
      });

      this.setState({ webViewerLoaded: true });
    });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
    let i = window.WebViewer.getInstance(document.getElementById('webViewerReference'));
    i.closeDocument();
    i.dispose();
  }

  // onResize = () => {
  //   const header = document.getElementById("usersScreenHeader");
  //   const webViewerContainer = document.getElementById("webViewerContainer");

  //   // caculate offset to subtract
  //   let offset = header.offsetHeight;

  //   const newHeight = window.innerHeight - offset;

  //   webViewerContainer.setAttribute("style", `height:${newHeight}px`);
  // };

  deselectReference = () => {
    this.docViewer.clearSelection();
    this.annotManager.deselectAllAnnotations();
  };

  confirmExistingReference = () => {
    const annotation = this.annotManager.getAnnotationById(this.state.selectedReferenceAnnotationId);

    this.props.onAddReference(
      annotation.PageNumber - 1,
      annotation.Id
    );
  };

  confirmReference = () => {
    const selectedTextQuads = this.docViewer.getSelectedTextQuads()[this.state.currentPageIndex]
    const highlight = new this.instance.Annotations.TextHighlightAnnotation();

    highlight.PageNumber = this.state.currentPageIndex;
    highlight.StrokeColor = new this.instance.Annotations.Color(122, 106, 141);
    highlight.FillColor = new this.instance.Annotations.Color(122, 106, 141);
    highlight.Quads = selectedTextQuads;
    highlight.Opacity = 0.5;
    highlight.BlendMode = "normal";

    const annotManager = this.docViewer.getAnnotationManager();
    annotManager.addAnnotation(highlight);
    annotManager.drawAnnotations(highlight.PageNumber);
  };

  render() {
    return (
      <div className={styles.webViewerContainer} id="webViewerContainer">
        <div id="webViewerReference" className={styles.webViewer} ref={this.viewer}></div>
      </div>
    );
  }
}

PDFTReference.propTypes = {
  doc: PropTypes.object,
  docId: PropTypes.number,
  docExtension: PropTypes.string,
  versionId: PropTypes.number,
  onTextSelection: PropTypes.func,
  onAnnotationSelect: PropTypes.func,
  onAnnotationDeselect: PropTypes.func,
  onAddReference: PropTypes.func
};

export default PDFTReference;
