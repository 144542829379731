import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";
import TextTruncate from "react-text-truncate";
import styles from "./DocumentThumbnail.module.scss";
import { apiUrl } from "../../lib/axios-client";
import history from "../../history";

import thumbnailPlaceholder from "../../images/icons/svg/thumbnail-placeholder.svg";
import errorThumbnailPlaceholder from "../../images/icons/svg/error-thumbnail-placeholder.svg";
import linkSVG from "../../images/icons/svg/link-grey.svg";
import Warning from "../../images/icons/png/warning.svg";

const DocumentThumbnail = ({index, doc, uploadProgress, filesUploaded, refError, closeFailedReference, fromDraft}) => {
  let linkTo = `/documents/${doc.id}`;
  if (doc.reference_document) {
    if (fromDraft) {
      linkTo = `/references/${doc.id}`
    } else {
      linkTo = `/references/${doc.id}/library`
    }
  }

const referenceLibrary = window.location.pathname.match(/^\/libraries\/\d+\/references/)
const documentName = doc.name && doc.name.split('.').slice(0, -1).join('.')
  return (
    <div className={history.location.pathname.match(/^\/drafts/) || referenceLibrary ? styles.documentContainerDark : styles.documentContainer}>
      {refError && <div className={filesUploaded && !refError ? styles.loaderHeading : styles.heading}>
        <span className={styles.headingText}>{filesUploaded ? documentName : doc.title}</span>
        {refError && <span
          className={styles.closeFailedReference}
          onClick={() => closeFailedReference(index)}
        >
          &times;
        </span>
        }
      </div>}
      <Link to={linkTo}>
        {!refError && <div className={filesUploaded ? styles.loaderHeading : styles.heading}>
          <TextTruncate line={2} truncateText="..." text={filesUploaded ? doc.name : doc.title} />
        </div>}
        {!refError && filesUploaded && <div className={styles.progressBar}>
          {uploadProgress(doc, index)}
        </div>}
        {refError && <div className={styles.errorBox}>
          <img src={Warning} className={styles.warning} />
          <span className={styles.errorMessage}>
            This file did not upload.
          </span>
        </div>}
        {<div className={styles.docInfoContainer}>
          {!filesUploaded && referenceLibrary && <div className={styles.createdTime}>
            <img src={linkSVG} />
            <div className={styles.annotationCount}>{doc.annotation_count ? doc.annotation_count : 0}</div>
          </div>}
          {!filesUploaded && <div className={styles.createdTime}>
            {moment(referenceLibrary ? doc.created_at : doc.updated_at).calendar(null, {
              lastDay: "[Yesterday]",
              sameDay: "[Today]",
              sameElse: "MMMM Do"
            })}
          </div>}
        </div>}
        {!doc.is_video && !filesUploaded && (
          <div
            className={styles.thumbnail}
            style={{ background: `url('${apiUrl}/documents/${doc.id}/page/1')` }}
          />
        )}
        {filesUploaded && (
          <div className={styles.thumbnail}>
            {!refError ? <img src={thumbnailPlaceholder} /> : <img src={errorThumbnailPlaceholder} />}
          </div>
        )}
        {doc.is_video && (
          <div
            className={styles.thumbnail}
            style={{ background: `url('${doc.video_poster_url || thumbnailPlaceholder}')` }}
          />
        )}
      </Link>
    </div>
  )
};

DocumentThumbnail.propTypes = {
  doc: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    filename: PropTypes.string,
    thumbnail: PropTypes.string
  }).isRequired
};

export default DocumentThumbnail;
