/* External libraries */
import React from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import Cookies from "universal-cookie";

/* Assets */
import "./App.css";

/* Components */
import Login from "./components/Login/Login";
import Users from "./components/Users/Users";
import Libraries from "./components/Libraries/Libraries";
import References from "./components/Documents/References";
import Documents from "./components/Documents/Documents";
import DocumentLibraries from "./components/Documents/DocumentLibraries";
import ReferenceLibraries from "./components/Documents/ReferenceLibraries";
import DocumentViewer from "./components/Documents/DocumentViewer";
import ReferenceViewer from "./components/Documents/ReferenceViewer";
import Approvers from "./components/Documents/Approvers";
import DraftUpload from "./components/Drafts/Upload/DraftUpload";
import DraftUpdate from "./components/Drafts/Upload/DraftUpdate";
import DraftVideoUpdate from "./components/Drafts/Upload/DraftVideoUpdate";
import Drafts from "./components/Drafts/Drafts";
import PasswordCreate from "./components/Password/PasswordCreate";
import PasswordForgot from "./components/Password/PasswordForgot";
import PasswordLinkExpired from "./components/Password/PasswordLinkExpired";
import PasswordSetup from "./components/Password/PasswordSetup";
import Tags from "./components/Tags/Tags";
import NewAccount from "./components/2FA/NewAccount";
import ReferencesLibrary from "./components/ReferencesLibrary";
import SetupSecurity from "./components/SetupSecurity";
import PersonalInfo from "./components/PersonalInfo";

import history from "./history";

import Notification from "./components/Common/Notification";

import security from "./services/Security";
import ActivityLog from "./components/ActivityLog";
import { ActivateAccount } from "./components/Login/ActivateAccount";

/* Variables */

class App extends React.Component {
  constructor() {
    super();
  }

  render() {
    if (security.checkAuth()) {
      return (
        <div>
          <Notification />
          <Router history={history}>
            <Switch>
              <Route exact path="/users" component={Users} />
              <Route exact path="/users/:id" component={Users} />
              <Route exact path="/libraries" component={Libraries} />
              <Route exact path="/libraries/:id" component={Libraries} />
              <Route exact path="/activitylog" component={ActivityLog} />
              <Route exact path="/personalinfo" component={PersonalInfo} />
              <Route exact path="/setupsecurity" component={SetupSecurity} />
              <Route
                exact
                path="/libraries/:id/documents"
                component={Documents}
              />
              <Route
                exact
                path="/libraries/:id/references"
                component={References}
              />
              <Route
                exact
                path="/documents/:id/update"
                component={DraftUpdate}
              />

              <Route
                exact
                path="/documents/:id/approvers"
                component={Approvers}
              />
              <Route exact path="/documents/:id" component={DocumentViewer} />
              <Route
                exact
                path="/document_libraries"
                component={DocumentLibraries}
              />
              <Route
                exact
                path="/reference_libraries"
                component={ReferenceLibraries}
              />
              <Route
                exact
                path="/references_library/:id"
                component={ReferencesLibrary}
              />
              <Route exact path="/references/:id" component={ReferenceViewer} />
              <Route exact path="/references/:id/:library" component={ReferenceViewer} />
              <Route
                exact
                path="/references/:id/pid/:blockIdentifier"
                component={ReferenceViewer}
              />
              <Route exact path="/tags" component={Tags} />
              <Route exact path="/tags/:id" component={Tags} />
              <Route exact path="/drafts/:id/approvers" component={Approvers} />
              <Route
                exact
                path="/drafts/:id/update_video"
                component={DraftVideoUpdate}
              />
              <Route exact path="/drafts/:id/update" component={DraftUpdate} />
              <Route exact path="/drafts/upload" component={DraftUpload} />
              <Route exact path="/drafts/:id" component={DocumentViewer} />
              <Route exact path="/drafts" component={Drafts} />
              <Redirect from="/:user_id/drafts/:document_id" to={{ pathname: "/drafts/:document_id", search: `${window.location.search}` }} />

              <Redirect to="/drafts" />
            </Switch>
          </Router>
        </div>
      );
    } else {
      return (
        <div>
          <Notification />
          <Router history={history}>
            <Switch>
              <Route exact path="/" component={Login} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/passwordlinkexpired" component={PasswordLinkExpired} />
              <Route
                exact
                path="/password/create/:token"
                component={PasswordCreate}
              />
              <Route
                exact
                path="/2fa/password/create/:token"
                component={NewAccount}
              />
              <Route
                exact
                path="/password/setup/:token/:title/:email/:documentId"
                component={PasswordSetup}
              />
              <Route exact path="/password/forgot" component={PasswordForgot} />
              <Route
                exact
                path="/password/forgot/:token"
                component={PasswordCreate}
              />
              <Route
                exact
                path="/:user_id/drafts/:document_id"
                component={ActivateAccount}
              />
              <Redirect to="/login" />
            </Switch>
          </Router>
        </div>
      );
    }
  }
}

export default App;
