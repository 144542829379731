import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import styles from "./DocumentReferencePanel.module.scss";

/* UI Kit */
import {
  Uikon,
  UikButton,
  UikFormInputGroup,
  UikInput,
  UikDivider,
  UikDropdown,
  UikDropdownItem
} from "@uik";
import "@uik/styles.css";
import "../../font.scss";

/* Assets */
import linkIcon from "../../images/icons/svg/link-icon.svg"

/* Variables */

class DocumentReferencePanel extends React.Component {
  constructor(props) {
    super();
    this.resizeTimeout = null;

    this.state = {
      conversation: {},
      documentReferences: props.referenceDocuments ? props.referenceDocuments : []
    };
  }

  componentWillMount = () => { };

  componentDidMount = () => {
    window.addEventListener("resize", this.onResize);
  };

  sidePanelOptionsCallback = element => {
    this.onResize();
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  onResize = () => {
    //resize the comment panel based on window height and elements
    const header = document.getElementById("usersScreenHeader");
    const attachmentHeader = document.getElementById(
      "sidePanelAttachmentHeader"
    );
    const attachmentPanel = document.getElementById(
      "sidePanelAttachmentOptions"
    );

    // caculate offset to subtract
    let offset = 0;
    if (header) {
      //offset += header.offsetHeight;
    }
    if (attachmentHeader) {
      offset += attachmentHeader.offsetHeight;

      const newHeight = window.innerHeight - offset - 1;

      attachmentPanel.setAttribute("style", `height:${newHeight}px`);
    }
  };

  renderDocumentReference = (ref, idx) => {
    return (
      <div key={ref.id} className={styles.referenceContentUploading}>
        <Link
          to={{
            pathname: `/references/${ref.id}/main`,
            state: {
              readOnly: true,
              docId: this.props.docId,
              versionId: this.props.versionId,
              versionNumber: this.props.versionNumber,
              originalDocumentId: this.props.originalDocumentId
            }
          }}
          className={styles.referenceLink}
        >
          <div className={styles.attachmentDocumentTitle}>{ref.title}</div>
          <div className="clear"></div>
        </Link>
      </div>
    );
  };

  render() {
    const actionsDropDown = ({ onClick }) => {
      return <div onClick={onClick}>&#9679;&#9679;&#9679;</div>;
    };

    return (
      <div
        id="documentAttachmentPanel"
        className={styles.documentReferencePanel}
      >
        <div id="sidePanelAttachmentHeader" className={styles.sidePanelHeader}>
          <span className={styles.sidePanelHeadingTitle}>References</span>
          <span
            className={styles.closeComment}
            onClick={() => this.props.close()}
          >
            &times;
          </span>
        </div>
        <div
          id="sidePanelAttachmentOptions"
          className={this.state.documentReferences.length > 0 ? styles.sidePanelOptions : styles.emptySidePanelOptions}
          ref={this.sidePanelOptionsCallback}
        >
          {this.state.documentReferences.length === 0 &&
          <div className={styles.emptyStateMessage}>
            <img src={linkIcon}></img>
            <span className={styles.emptyStateMessageLineOne}>
              There are no references.
            </span>
            <span className={styles.emptyStateMessageLineTwo}>
              To create one, highlight some text and select the link icon.
            </span>
          </div>
          }
          {this.state.documentReferences.map((attachment, idx) => {
            return this.renderDocumentReference(attachment, idx);
          })}
        </div>
      </div>
    );
  }
}

DocumentReferencePanel.propTypes = {
  close: PropTypes.func,
  selectConversation: PropTypes.func,
  selectReference: PropTypes.func,
  referenceBlockId: PropTypes.string,
  referenceId: PropTypes.number,
  docId: PropTypes.number,
  pageNumber: PropTypes.string
};

export default DocumentReferencePanel;
