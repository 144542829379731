import React from "react";
import Cookies from "universal-cookie";
import { Link, Router, Route } from "react-router-dom";
import { Row } from "react-grid-system";
import history from "../../../history";
import styles from "./Header.module.scss";
import HttpClient from "../../../lib/HttpClient"
import breifcase from "../../../images/icons/svg/breifcase.svg"
import profileIcon from "../../../images/icons/svg/profileIcon.svg"
import logOut from "../../../images/icons/svg/logOut.svg"
import security from "../../../services/Security";
import HubspotChat from "../../Common/HubspotChat";

/* UI Kit */
import { UikButton, UikDropdown, UikDropdownItem, Uikon } from "@uik";
import "@uik/styles.css";
import "../../../font.scss";

import papercurveLogo from "../../../images/logos/papercurve-logo-colour.svg";
import CloseSVG from "../../../images/icons/svg/close.svg";

/* Variables */
const cookies = new Cookies();

class Header extends React.Component {
  constructor() {
    super();

    const currentUserRole = security.getUserRole();
    const currentUserFirstName = security.getUserFirstName();
    const currentUserLastName = security.getUserLastName();

    this.state = {
      currentUser: {
        role: currentUserRole,
        first_name: currentUserFirstName,
        last_name: currentUserLastName
      }
    };
  }

  handleLogout = e => {
    e.preventDefault();
    security.logout();
    history.push("/");
    HttpClient.delete("/logout.json", { }, { withCredentials: true })
  };

  handleWorkspace = e => {
    e.preventDefault();
    history.push("/users");
  };

  handleMyAccount = e => {
    e.preventDefault();
    history.push("/personalinfo");
  };

  render() {
    const documents =
      window.location.pathname.match(/^\/document_libraries/) ||
        window.location.pathname.match(/^\/libraries\/\d+\/documents/) ||
        window.location.pathname.match(/^\/documents\/\d+\/*([\w]*-[\w]*)*\/?(\d)*$/) ||
        this.props.latestApproved
        ? true
        : false;
    const drafts = window.location.pathname.match(/^\/drafts/) && !this.props.latestApproved ? true : false;
    const references = window.location.pathname.match(/^\/reference_libraries/) || window.location.pathname.match(/^\/libraries\/\d+\/references/)
    const logoURL =
      this.state.currentUser.role === "admin" ||
        this.state.currentUser.role === "author"
        ? "/drafts"
        : "/document_libraries";

    const namePlateDropDown = ({ onClick }) => {
      const firstName = this.state.currentUser.first_name;
      const lastName = this.state.currentUser.last_name;
      return (
        <div className={styles.upgradeNotifierContainer}>
          <div className={styles.namePlate} onClick={onClick}>
              <div className={styles.userNamePlateAvatar}>
                <span className={styles.userNamePlateInitials}>
                  {firstName.charAt(0)}
                  {lastName.charAt(0)}
                </span>
              </div>
            <span className={styles.namePlateName}>
              {firstName}
              <Uikon className={styles.namePlateChevron}>arrow_right</Uikon>
            </span>
          </div>
        </div>
      );
    };

    return (
      <Row className={styles.usersScreenHeaderContainer}>
        <div className={styles.usersScreenHeader} id="usersScreenHeader">
          <div className={styles.usersScreenHeaderLogo}>
            <a href={logoURL}>
              <img className="authentication-logo" src={papercurveLogo} />
            </a>
          </div>
          <div className={styles.usersScreenHeaderMenu}>
            <a href="/drafts" className={drafts ? styles.selectedMenuItem : ""}>
              Draft
            </a>
            <a
              href="/document_libraries"
              className={documents ? styles.selectedMenuItem : ""}
            >
              Approved
            </a>
            {this.state.currentUser.role !== "viewer" && 
            <a
              href="/reference_libraries"
              className={references ? styles.selectedMenuItem : ""}
            >
              References
            </a>}
          </div>
          <div className={styles.headerNamePlate}>
            <div>
              <UikDropdown
                DisplayComponent={namePlateDropDown}
                position="bottomRight"
              >
                {( (this.state.currentUser.role !== "viewer") &&
                  <UikDropdownItem className={styles.firstDropdownItem} onClick={e => this.handleWorkspace(e)}>
                  <img className={styles.dropdownIcon} src={breifcase} />
                    Workspace
                  </UikDropdownItem>
                )}
                  <UikDropdownItem onClick={e => this.handleMyAccount(e)}>
                  <img className={styles.dropdownIcon} src={profileIcon} />
                    My Account
                  </UikDropdownItem>
                  <UikDropdownItem onClick={e => this.handleLogout(e)}>
                  <img className={styles.dropdownIcon} src={logOut} />
                    Sign out
                  </UikDropdownItem>
              </UikDropdown>
            </div>
          </div>
          <div className={styles.divider}>
          </div>
          <div className="hs-chat-container">
            <HubspotChat />
          </div>
        </div>
      </Row>
    );
  }
}

export default Header;
